.navbar1 {
  background-color: black;
  display: -ms-inline-flexbox;
}
.navbar1 .navbar-nav {
  display: -webkit-box;
  display: ruby-text;
}


.nav-btn {
  background-color: rgb(37, 87, 156) !important;
  border: none !important;
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
  padding: 1px 15px !important;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
}

h1 {
  border: 5px dotted blue;
}

marquee {
  background-color: rgb(168, 0, 0);
  color: white;
  border: 2px solid yellow;
  margin-top: 3px;
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
}

.advertisements {
  border: 3px solid black;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.search_result h3 {
  /* background-color: rgb(29, 158, 130); */
  background-color: #0d2bcf;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-top: 5px;
  padding: 5px 0px;
  font-family: 'Times New Roman', Times, serif;
}

input {
  font-size: 16px;
}

.pera-1 {
  font-family: 'Times New Roman', Times, serif;
}

.pera-2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.search-bar {
  width: 236px;
}

/* ..........banner........... */

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #28a745;
  background: linear-gradient(to right, #237A57, #093028);
  border-radius: 8px;
}

.game {
  text-align: center;
}

.game h5 {
  font-size: 30px;
  -webkit-text-stroke: 1px #fff;
  text-shadow: 6px 4px 4px #000;
  color: red;
}



/* result chart from here */

/* ResultChart.css */

/* .result-chart-container {
  padding: 20px;
  background-color: #004c0e43;
  border-radius: 20px;
} */

.result-chart-container h6 {
  border: 2px solid #003cff;
  color: #fff;
  background: #001899;
}

.result-chart-container p {
  background: linear-gradient(to right, #78ffd6, #007991);
  margin-top: -0.5em;
}

.chart-heading {
  text-align: center;
  color: white;
  background: #002f99;
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
}

.game-container {
  margin-bottom: 20px;
  padding: 10px;
  background: linear-gradient(to right, #ece9e6, #ffffff);
}

.game-name {
  text-align: center;
  font-size: 16px;
  color: white;
  background: linear-gradient(to right, #4b79a1, #283e51);
  padding: 5px;
}

.game-time {
  text-align: center;
  color: black;
  padding: 5px;
}

.game-results {
  text-align: center;
  position: relative;
  padding: 5px;
}

.result-left,
.result-right {
  color: black;
  background: linear-gradient(to right, #bbac7c, #ffffff);
  padding: 5px;
  position: absolute;
  width: 25%;
}

.result-left {
  left: 25%;
}

.result-right {
  right: 25%;
}

/* about/information section from here */
/* General styles */
.info-section {
  background: linear-gradient(135deg, #0e53dd 0%, #7b2727 100%) !important;
  /* Background gradient */
  padding: 40px !important;
  /* Padding for better spacing */
  border-radius: 10px !important;
  /* Rounded corners */
  margin: 20px 0 !important;
  /* Margin for separation */
}

.gr-twenty {
  background: linear-gradient(to right, #002d22, #156858, #041411);
  /* Card background color */
  border-radius: 10px !important;
  /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  /* Shadow for depth */
  padding: 40px !important;
  /* Padding for spacing */
  transition: transform 0.3s ease !important;
  /* Smooth hover transition */
}

/* .gr-twenty:hover {
  transform: translateY(-10px) !important;

} */

/* .sattaking-info-one,
.sattaking-info-two {
  margin-bottom: 40px !important;
} */

.sattaking-info h2,
.sattaking-info h3 {
  color: #fb9537 !important;
}

.sattaking-info p,
.sattaking-info p a {
  color: #fff !important;
}


ol {
  font-size: 16px !important;
  /* Text font size */
  line-height: 1.7 !important;
  /* Line height for readability */
  color: #ffffff !important;
  /* Text color */
  margin-bottom: 20px !important;
  /* Space below paragraphs and lists */
}

ol {
  padding-left: 20px !important;
  /* Padding for ordered list */
  list-style-type: decimal !important;
  /* Decimal list style */
}

ol li {
  margin-bottom: 10px !important;
  /* Space between list items */
}

a {
  color: #007bff !important;
  /* Link color */
  text-decoration: none !important;
  /* No underline */
}

a:hover {
  color: #0056b3 !important;
  /* Hover color for links */
  text-decoration: underline !important;
  /* Underline on hover */
}

.navbar-navv li {
  list-style: none;
}

p,
ol li {
  margin-bottom: 16px !important;
  list-style: none;
  /* Space between paragraphs and list items */
}

/* Buttons */
.btn-primary {
  background-color: #007bff !important;
  /* Button background color */
  border: none !important;
  /* No border */
  color: #fff !important;
  /* Text color */
  padding: 10px 20px !important;
  /* Padding for spacing */
  border-radius: 5px !important;
  /* Rounded corners */
  transition: background-color 0.3s ease !important;
  /* Smooth transition */
}

.btn-primary:hover {
  background-color: #0056b3 !important;
  /* Hover color for buttons */
}

/* Responsive adjustments */
/* @media (max-width: 768px) {
  .gr-twenty {
    padding: 20px !important;
  
  }

  p,
  ol {
    font-size: 14px !important;
  }

  h2 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 20px !important;
  }
} */


/* ...................footer-container..................... */

.footer-container {
  border: 3px solid black;
  text-align: center;
  margin-top: 10px;
  font-family: 'Times New Roman', Times, serif !important;
}

.footer-container h2 {
  font-size: 24px !important;
  font-weight: bold !important;
  color: black !important;
  margin-top: 10px;
}

.footer-container p {
  font-size: 16px !important;
  font-weight: bold !important;
  color: black !important;
  padding: 0rem 1rem;
}

.footer-buttons {
  background-color: #090a4d;
  border: 2px solid red;
  text-align: center;
  border-radius: 21px;
  margin-top: 15px;
  color: #fff;
}

.footer h5 {
  color: #090a4d !important;
  font-weight: bold;
}


/* footer button styling */
.footerButton button {
  background: red;
  width: 100%;
  border-radius: 1rem;
  border: 0px;
  color: #fff;
}

.footerButton .last-button {
  width: 32%;
}




/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #156858;
  font-weight: bold;
  border-radius: 3px;
}

#go-button {
  background-color: #156858;
  color: #fb9537;
  cursor: pointer;
}

#go-button:hover {
  background-color: #002d22;
}


.button p {
  margin: 15px 9px 3px 9px;
}

/* .......about */

.about {

  font-family: 'Times New Roman', Times, serif;
  margin-top: 15px;
}


.about h2 {
  color: black !important;
  font-weight: bold !important;
}

.about h3 {
  color: black !important;
  font-weight: bold !important;
}

.about p {
  color: black !important;
  font-weight: 500;
}




/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #002d22;
  border-radius: 10px;

}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #002d22;
  /* border: 1px solid; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 10px;
  background-color: #156858;
}



/* ============antd table styling========== */
/* .ant-table-content thead {
  text-align: center !important;
} */



/* .ant-table-content thead tr,
.ant-table-content thead td {
  background-color: #fbc503;
  border: 1px solid #e7aa26;
  border-radius: 0px;
}

/* background-color: #fbc503;
border: 1px solid #e7aa26;
border-radius: 0px; */

.ant-table-thead>tr>td,
.ant-table-thead th {
  border-radius: 0px !important;
  border-color: #000;
  /* background-color: #a61111 !important; */
  color: #fff !important;
  /* font-size: 0.6rem; */
  font-weight: bold;
  text-wrap: nowrap;
}

.ant-table-wrapper {
  border-collapse: separate !important;
}


.ant-table-wrapper,
.ant-table-thead tr td,
.ant-table-content,
.ant-table-thead th {
  /* background: transparent !important; */
  border-bottom: 1px solid #f0f0f0;
}


.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child .ant-table-content {
  border-start-start-radius: 0px !important;
}

/* .ant-table-content tr {
  text-align: center !important;

  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;

} */

/* .ant-table-wrapper .ant-table-container table>tbody>tr>*:first-child {
  background-color: #ffddff !important;
 
} */

.ant-table-content td,
.ant-table-content th {
  padding: 0px 1.5rem !important;
  text-align: center !important;

  border: 1px solid !important;
  vertical-align: middle !important;
}

.ant-table-content tr:nth-child(even) {
  background-color: #f9f9f9 !important;
}

.ant-table-wrapper table {
  border-radius: 0px !important;
  /* margin-top: -6px; */
}

/* table tbody tr:hover {
  background-color: #f0f0f0;
  color: red;

} */

.custom-table {
  border-collapse: separate;
  border-spacing: 5px;
}

/* antd styling */

.ant-table-wrapper {
  text-wrap: nowrap;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background-color: #092248;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  padding: 0px 2px;
}



/* =====table drop down style====== */
.css-b62m3t-container {
  border: 1px solid #156858;
  border-radius: 3px;
}



/* header button styling */

.reasult-header .btn {
  border-radius: 10px;
  background-color: #25579d;
  color: #fff;
  border: 4px solid #000;
  padding: 2px 15px;
  text-decoration: none;
}


.monthYrTbl {
  text-transform: uppercase;
}


/* ======= react toas ===== */
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.blinking-text {
  animation: blink 2s infinite;
  color: #00b8ff;
  /* font-weight: bold; */

}


/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}




/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #e67e22;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #fef9e7;
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #e74c3c;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.advertisementSection .fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.advertisementSection .fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}